import React from "react"
import slugify from "slugify"
import {
  Link as GatsbyLink,
  StaticQuery,
  graphql,
  useStaticQuery,
} from "gatsby"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import {
  Toolbar,
  Link,
  Typography,
  Box,
  Container,
  Divider,
  Card,
  CardMedia,
  CardContent,
  Chip,
  Grid,
} from "@material-ui/core"
import rehypeRaw from "rehype-raw"

import download from "../images/download.svg"
import Layout from "../components/layout"
import ReactMarkdown from "react-markdown"
import analytics from "../images/services/dataAnalytics.jpg"
const StyledToolbar = withStyles({
  root: {
    background: "#031242",
  },
})(Toolbar)

function DownloadsPage() {
  const classes = useStyles()
  // const myRef = React.useRef(null)

  // const executeScroll = ref => {
  //   if (myRef.current) window.scrollTo(0, myRef.current.offsetTop)
  // }

  const query = useStaticQuery(graphql`
    query {
      seo: strapiSeo {
        downloadsPage {
          pagetitle
          description
          keywords
        }
      }
      downloads: allStrapiDownload {
        edges {
          node {
            title
            slug
            description
            image {
              publicURL
            }
          }
        }
      }
    }
  `)

  return (
    <Layout
      siteTitle={query.seo.downloadsPage.pagetitle ?? "Downloads"}
      description={query.seo.downloadsPage.description ?? ""}
      keywords={query.seo.downloadsPage.keywords ?? ""}
    >
      <StyledToolbar />
      <Container maxWidth="md">
        <Box py={5}>
          <Typography variant="h4" gutterBottom color="primary" align="center">
            <b>Downloads</b>
          </Typography>
        </Box>
        <Box pt={2} pb={8}>
          <Grid container py={5}>
            {query.downloads.edges.map(item => {
              return (
                <Grid item xs={12}>
                  <Box my={2} boxShadow={2}>
                    <Card>
                      <Grid container>
                        <Grid item xs={12} md={3}>
                          <CardMedia
                            className={classes.cardMedia}
                            image={item.node.image?.publicURL}
                            component={GatsbyLink}
                            to={"/downloads/" + item.node.slug}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <CardContent>
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                            >
                              <Typography
                                variant="h5"
                                color="primary"
                                gutterBottom
                              >
                                <Link
                                  color="inherit"
                                  underline="none"
                                  component={GatsbyLink}
                                  to={"/downloads/" + item.node.slug}
                                >
                                  <b>{item.node.title}</b>
                                </Link>
                              </Typography>
                              <Typography variant="body1">
                                <ReactMarkdown
                                  rehypePlugins={[rehypeRaw]}
                                  children={item.node.description}
                                  transformImageUri={uri =>
                                    uri.startsWith("http")
                                      ? uri
                                      : `${process.env.GATSBY_IMAGE_BASE_URL}${uri}`
                                  }
                                  className="dynamicContent"
                                  escapeHtml={false}
                                />
                              </Typography>
                            </Box>
                          </CardContent>
                        </Grid>
                      </Grid>
                    </Card>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Container>
    </Layout>
  )
}

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: "none",
    color: "black",
  },
  cardMedia: {
    height: "100%",
    paddingTop: "56.25%",
  },
}))

export default DownloadsPage

{
  /* <StaticQuery
            query={graphql`
              query {
                allStrapiDownloads {
                  edges {
                    node {
                      Resource {
                        name
                        Type {
                          description
                          filetype
                          files {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            `}
            render={data => {
              if (data.allStrapiDownloads.edges.length === 0)
                return (
                  <Grid item xs={12}>
                    No Downloads yet
                  </Grid>
                )
              return (
                <>
                  {data.allStrapiDownloads.edges.map(item => {
                    return (
                      <Grid item xs={12}>
                        {item.node.Resource.map(items => {
                          return (
                            <Box py={2}>
                              <Typography variant="h5" gutterBottom>
                                <b>{items.name}</b>
                              </Typography>
                              {items.Type.map(item => {
                                JSON.stringify(item.files.url)

                                return (
                                  <>
                                    <Box>
                                      <Typography variant="subtitle1">
                                        <b>{item.filetype}</b>
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <ReactMarkdown
                                        source={item.description}
                                        transformImageUri={uri =>
                                          uri.startsWith("http")
                                            ? uri
                                            : `${process.env.GATSBY_IMAGE_BASE_URL}${uri}`
                                        }
                                        className="dynamicContent"
                                        escapeHtml={false}
                                      />
                                    </Box>
                                    {item.files.map(items => {
                                      return (
                                        <Box pb={4} display="flex">
                                          <img
                                            src={download}
                                            height="18px"
                                            width="18px"
                                            alt="download file"
                                          />
                                          <Box pl={1}>
                                            <a
                                              href={items.url}
                                              download
                                              className={classes.link}
                                            >
                                              <Typography variant="body2">
                                                {items.url.substring(
                                                  items.url.lastIndexOf("/") + 1
                                                )}
                                              </Typography>
                                            </a>
                                          </Box>
                                        </Box>
                                      )
                                    })}
                                  </>
                                )
                              })}
                              <Divider width="100%" />
                            </Box>
                          )
                        })}
                      </Grid>
                    )
                  })}
                </>
              )
            }}
          /> */
}
